import React from "react"
import { Link } from "gatsby"
import { Globe } from "../Icons"

const Footer = () => {
    return (
        <footer className="z-10">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
    
              {/* Social links */}
              <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
                <li>
                  <a target="_blank" rel="noreferrer" href="https://oligomaster.com/" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-8 h-8" aria-label="Email">
                    <Globe className="w-4 h-4 fill-current" />
                  </a>
                </li>
                <li className="ml-4">
                  <a target="_blank" rel="noreferrer" href="mailto:contact@oligomaster.com" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-8 h-8" aria-label="Email">
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <title>Email</title>
                        <path d="M10.6055 8.082L16 11.4922V4.5273zM0 4.5273v6.9649L5.3945 8.082zM15 2.5H1c-.5 0-.8945.371-.9688.8516L8 8.6016l7.9688-5.25C15.8945 2.871 15.5 2.5 15 2.5zM9.6914 8.6875l-1.418.9297A.4883.4883 0 018 9.6992a.4883.4883 0 01-.2734-.082l-1.418-.9336-6.2774 3.9726C.1095 13.1328.504 13.5 1 13.5h14c.496 0 .8906-.3672.9688-.8438zm0 0" />
                    </svg>
                  </a>
                </li>
                <li className="ml-4">
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/oligomasterinc" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <title>Twitter</title>
                      <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                    </svg>
                  </a>
                </li>
                <li className="ml-4">
                  <a target="_blank" rel="noreferrer" href="https://linkedin.com/company/oligomaster" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-8 h-8" aria-label="LinkedIn">
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <title>LinkedIn</title>
                        <path d="M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964-1.424 1.96v3.778h-2.37V6H8.51V7.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758.614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377zm1.188 8.68H2.37V6h2.376v7.635zM14.816 0H1.18C.528 0 0 .516 0 1.153v13.694C0 15.484.528 16 1.18 16h13.635c.652 0 1.185-.516 1.185-1.153V1.153C16 .516 15.467 0 14.815 0z" />
                  </svg>
                  </a>
                </li>
              </ul>
    
              {/* Copyrights note */}
              <div className="text-sm text-gray-600 mr-4">
                <div className="hidden md:block md:col-span-3 lg:col-span-4">
                    <div className="mb-2">
                    {/* Logo */}
                    <Link to="/" className="inline-block">
                        <svg className="w-8 md:w-12 h-8 md:h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 963.13 740.57">
                            <defs>
                            <linearGradient id="footer-logo-gradient" x1="498.92" x2="498.92" y1="867.8" y2="265.47" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#243d98"/>
                                <stop offset=".01" stopColor="#243d98"/>
                                <stop offset=".28" stopColor="#243d98"/>
                                <stop offset="1" stopColor="#0b77be"/>
                            </linearGradient>
                            </defs>
                            <title>Oligomaster</title>
                            <path fill="url(#footer-logo-gradient)" d="M701.66 631.48c-8.94-58.92-40.47-103.63-87.18-139.21-17.71-13.49-35-28-50.32-44.07-29.39-30.87-34.78-68-20.88-107.38 14.25-40.36 43.82-65.13 85.67-72.35 65.37-11.29 116.67 15.69 151.92 68.38 39.35 58.81 73.72 121 109.8 181.93q35.88 60.64 70.41 122.07c33.71 60 22.62 134.77-26.29 181.66-69.88 67-183.25 51.64-233.07-31.57-6.09-10.17-12-20.44-19.21-32.72C640 828 580.68 867.71 499.25 867.8S358.14 828.69 315.2 757.93c-6 10.43-10.89 18.92-15.8 27.4q-43.38 75-129.93 79.1c-71.17 3.24-139.6-54-150.17-124.65-5.41-36.16.35-69.85 18.65-101.26 55.43-95.16 110.76-190.38 166.81-285.17 24.6-41.59 58.93-71.92 107.1-83.93 56.53-14.1 113.63 10.41 137.52 58.94 23.28 47.28 12.6 98.1-28.67 133.67-20.43 17.61-42 33.89-62.25 51.72-22.87 20.16-40.36 44.56-50.7 73.46-11.86 33.14-10.2 65 14.87 91.66 25.48 27.11 57.64 28 91 18.29 28.07-8.15 48-28.35 68.6-47.6 15.46-14.47 30.54-29.6 47.44-42.22 26.67-19.92 57.77-27.8 90.68-23.27 40.83 5.61 70.5 36.2 79.87 79.29.37 1.72 2.64 9.66 3.69 10.18s-.41-29.93-2.25-42.06z" transform="translate(-17.36 -127.23)"/>
                            <path fill="#243d98" d="M561.57 85.77a33.8 33.8 0 11-33-34.89 33.82 33.82 0 0133 34.89zM451.2 101.55a25.3 25.3 0 11-24.76 25.15 25.17 25.17 0 0124.76-25.15zM494.18 0c10.08 1.4 16.59 6.71 16.43 17.16s-6.42 16.54-16.77 16.43-16.52-6.42-16.45-16.76S484.05 1.18 494.18 0z"/>
                        </svg>
                    </Link>
                    </div>
                </div>
                &copy;&nbsp;2022&nbsp;<a className="text-blue-600 hover:underline" target="_blank" rel="noreferrer" href="https://oligomaster.com/">Oligomaster Inc</a>. All rights reserved.
            </div>
    
            </div>
    
          </div>
        </footer>
      )    
}

export default Footer
