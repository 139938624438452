import React from "react"
import { useCallback } from "react"
import Particles from "react-particles"
import { loadFull } from "tsparticles"
import particlesConfig from "./particlesConfig.json"

const Hero = () => {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
      }, [])
    
      const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
      }, [])

      return (
        <section className="relative" style={{
            background: "#151719",
        }}>

            {/* Illustration behind hero content */}
            <div className="absolute z-0 pointer-events-none">
                <Particles id="tsparticles" params={particlesConfig} init={particlesInit} loaded={particlesLoaded} />
            </div>
            {/* <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
                <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                        <stop stopColor="#FFF" offset="0%" />
                        <stop stopColor="#EAEAEA" offset="77.402%" />
                        <stop stopColor="#DFDFDF" offset="100%" />
                    </linearGradient>
                    </defs>
                    <g fill="url(#illustration-01)" fillRule="evenodd">
                    <circle cx="1232" cy="128" r="128" />
                    <circle cx="155" cy="443" r="64" />
                    </g>
                </svg>
            </div> */}

            <div className="max-w-6xl mx-auto px-4 sm:px-6 z-10">

                {/* Hero content */}
                <div className="pt-20 pb-12 md:pt-40 md:pb-20 lg:pt-64 lg:pb-48">

                    {/* Section header */}
                    <div className="text-center pb-0 md:pb-16">
                        <h1 className="text-5xl text-white font-extrabold leading-tighter tracking-tighter mb-8" data-aos="zoom-y-out">Disrupting, big-thinking, future-forward <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">material science technology</span></h1>
                        <div className="max-w-3xl mx-auto">
                            <p className="text-xl text-slate-100 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                                Expert advice for product development and research.
                            </p>
                            <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                                <div>
                                    <a className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="#contact">
                                        Contact Us
                                    </a>
                                </div>
                                <div>
                                    <a className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="https://oligomaster.com/" target="_blank" rel="noreferrer">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default Hero
