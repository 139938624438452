import React from "react"

const Renewable = ({ className, width = 32, height = 32, fill = "currentColor" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 511.993 511.993"
            width={width}
            height={height}
            className={className}
        >
            <path fill={fill} d="M255.996,38.396c-113.109,0-204.8,91.691-204.8,204.8c0,2.671,0.222,5.274,0.401,7.902L21.85,221.351
                c-5.001-5.001-13.099-5.001-18.099,0c-5,5.001-5,13.099,0,18.099l51.2,51.2c2.492,2.5,5.769,3.746,9.045,3.746
                s6.554-1.246,9.054-3.746l51.2-51.2c5.001-5,5.001-13.099,0-18.099c-5-5.001-13.099-5.001-18.099,0l-28.868,28.868
                c-0.119-2.355-0.486-4.642-0.486-7.023c0-98.807,80.393-179.2,179.2-179.2c82.893,0,152.61,56.653,172.996,133.205
                c5.743-3.302,12.203-5.205,19.004-5.205c2.116,0,4.19,0.273,6.246,0.614C431.689,104.018,351.612,38.396,255.996,38.396z"/>
            <path fill={fill} d="M508.25,272.542l-51.2-51.2c-2.5-2.5-5.777-3.746-9.054-3.746s-6.554,1.246-9.054,3.746l-51.2,51.2
                c-5.001,5.001-5.001,13.099,0,18.099c5,5.001,13.099,5.001,18.099,0l28.868-28.868c0.119,2.355,0.486,4.642,0.486,7.023
                c0,98.807-80.393,179.2-179.2,179.2c-82.893,0-152.61-56.653-172.996-133.205c-5.743,3.294-12.203,5.205-19.004,5.205
                c-2.116,0-4.19-0.273-6.246-0.614c22.554,88.593,102.63,154.214,198.246,154.214c113.109,0,204.8-91.691,204.8-204.8
                c0-2.671-0.222-5.274-0.401-7.902l29.747,29.747c5,5.001,13.099,5.001,18.099,0C513.242,285.641,513.242,277.543,508.25,272.542z"
                />
        </svg>
    )
}

export default Renewable
