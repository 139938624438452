import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Clients = () => {
    return (
        <section className="flex flex-col py-12 z-10">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="max-w-3xl mx-auto text-center pb-12">
                    <h2 className="font-normal font-robotocondensed tracking-tighter text-3xl uppercase">Affiliations &amp; Clients</h2>
                </div>

                {/* <h2 className="text-slate-700 text-center text-2xl">
                    Select Clients &amp; Partners
                </h2> */}
                <div className="flex flex-wrap justify-evenly">
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/xerox.png" alt="Xerox Research Center Canada" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/dupont.png" alt="DuPont" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/mcmaster-eng-v2.png" alt="McMaster University - Faculty of Engineering" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/nserc.png" alt="NSERC" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/forge.png" alt="The Forge" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/ontario-ce.png" alt="Ontario Center of Excellence" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/trimis.png" alt="TriMis" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/mcmaster-ip-v2.png" alt="McMaster Innovation Park" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/oleotek.png" alt="Oleotek" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/mcmaster-mri-v2.png" alt="McMaster Manufacturing Research Institute" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/pixiu.png" alt="Pixiu Solutions" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/aimateria-v2.png" alt="AI Materia" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/expineer-corp.png" alt="Expineer Corp" />
                    <StaticImage placeholder="blurred" className="max-w-[128px]" src="../../images/clients/netprocore-v2.png" alt="NetProCore" />
                </div>
            </div>
        </section>
    )
}

export default Clients
