import React, { useEffect } from 'react'

import 'aos/dist/aos.css';
import AOS from 'aos';

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Clients from "../components/Clients"
import Services from "../components/Services"
import Contact from "../components/Contact"
import Seo from "../components/Seo"

const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      // disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
  })

  return (
    <Layout>
      <Hero />
      <Clients />
      <Services />
      <Contact />
    </Layout>
)}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
