import React from "react"

const Leaf = ({ className, width = 32, height = 32, fill = "currentColor" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width={width}
            height={height}
            className={className}
        >
            <path
                d="M389.053,126.3A302.909,302.909,0,0,0,280.012,18.15L272,13.516l-8.012,4.634A301.084,301.084,0,0,0,113.4,279.042c0,3.445.06,6.944.177,10.4,1.592,46.856,19.511,86.283,51.82,114.018,24.724,21.225,56.438,34.182,90.607,37.273V496h32V240H256V408.528c-54.064-6.263-107.873-44.455-110.444-120.174-.106-3.095-.16-6.228-.16-9.312A270.286,270.286,0,0,1,272,50.673,270.286,270.286,0,0,1,398.6,279.042c0,3.084-.054,6.217-.16,9.313-2.056,60.573-36.907,97.127-78.444,112.536v33.867a156.188,156.188,0,0,0,58.607-31.3c32.309-27.735,50.228-67.162,51.82-114.017.117-3.456.177-6.955.177-10.4A300.948,300.948,0,0,0,389.053,126.3Z"
                fill={fill}
            />
        </svg>
    )
}

export default Leaf
