import React from "react"

const External = ({ className, width = 32, height = 32, fill = "currentColor" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={className}
        >
            <rect
                x="0"
                fill="none"
                width="24"
                height="24"
            />
            <g>
                <path
                    fill={fill}
                    d="M19 13v6c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V7c0-1.105.895-2 2-2h6v2H5v12h12v-6h2zM13 3v2h4.586l-7.793 7.793 1.414 1.414L19 6.414V11h2V3h-8z"
                />
            </g>
        </svg>
    )
}

export default External
