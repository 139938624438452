import React from "react"
import {
    Antimicrobial,
    External,
    Leaf,
    Lightbulb,
    Recycle,
    Renewable,
} from "../Icons"

const Services = () => {
    return (
        <section className="flex flex-col py-12 relative bg-slate-50 z-10">

            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="max-w-3xl mx-auto text-center pb-12">
                    <h2 className="font-normal font-robotocondensed tracking-tighter text-3xl uppercase">Expertise &amp; Services</h2>
                </div>

                {/* Items */}
                <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

                    {/* 1st item */}
                    <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full">
                        <div className="block p-4 bg-gradient-to-t from-[#243d98] to-[#0b77be] rounded-full mb-4">
                            <Antimicrobial fill="white" />
                        </div>
                        <h3 className="h4 text-center">Next Generation Antimicrobials</h3>
                        {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </div>

                    {/* 2nd item */}
                    <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full">
                        <div className="block p-4 bg-gradient-to-t from-[#243d98] to-[#0b77be] rounded-full mb-4">
                            <Recycle fill="white" />
                        </div>
                        <h3 className="h4 text-center">Sustainable Plastic Recycling</h3>
                        {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </div>

                    {/* 3rd item */}
                    <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full">
                        <div className="block p-4 bg-gradient-to-t from-[#243d98] to-[#0b77be] rounded-full mb-4">
                            <Leaf fill="white" />
                        </div>
                        <h3 className="h4 text-center">Agri-food Nexus: Bioplastics and Advanced Materials</h3>
                        {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </div>

                    {/* 4th item */}
                    <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full">
                        <div className="block p-4 bg-gradient-to-t from-[#243d98] to-[#0b77be] rounded-full mb-4">
                            <Renewable fill="white" />
                        </div>
                        <h3 className="h4 text-center">Renewable Solutions for Plastics</h3>
                        {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </div>

                    {/* 5th item */}
                    <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full">
                        <div className="block p-4 bg-gradient-to-t from-[#243d98] to-[#0b77be] rounded-full mb-4">
                            <Lightbulb fill="white" />
                        </div>
                        <h3 className="h4 text-center">Disruptive Flexible Polymer Technology and Processing Innovations</h3>
                        {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </div>

                    {/* 6th item */}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://oligomaster.com/"
                        className="cursor-pointer hover:scale-[102%] ease-in duration-300 relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full">
                        {/* <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl h-full"> */}
                            <div className="block p-4 bg-gradient-to-t from-[#243d98] to-[#0b77be] rounded-full mb-4">
                                <External fill="white" />
                            </div>
                            <h3 className="h4 text-center">And More!</h3>
                            <p className="text-gray-600 text-center">Click here to learn more about how Oligomaster can help you.</p>
                        {/* </div> */}
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Services
