import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Hamburger from 'hamburger-react'
import EscapeOutside from 'react-escape-outside'

const Header = () => {
  const [top, setTop] = useState(true)
  const [isOpen, setOpen] = useState(false)

  const isOpenOrScrolled = isOpen || !top

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${isOpenOrScrolled && 'bg-white backdrop-blur-sm shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mx-2">
            {/* Logo */}
            <Link to="/" className="flex items-center">
              <svg className="w-8 md:w-12 h-8 md:h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 963.13 740.57">
                <defs>
                  <linearGradient id="header-logo-gradient" x1="498.92" x2="498.92" y1="867.8" y2="265.47" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#243d98"/>
                    <stop offset=".01" stopColor="#243d98"/>
                    <stop offset=".28" stopColor="#243d98"/>
                    <stop offset="1" stopColor="#0b77be"/>
                  </linearGradient>
                </defs>
                <title>Oligomaster</title>
                <path fill={!isOpenOrScrolled ? "#fff" : "url(#header-logo-gradient)"} d="M701.66 631.48c-8.94-58.92-40.47-103.63-87.18-139.21-17.71-13.49-35-28-50.32-44.07-29.39-30.87-34.78-68-20.88-107.38 14.25-40.36 43.82-65.13 85.67-72.35 65.37-11.29 116.67 15.69 151.92 68.38 39.35 58.81 73.72 121 109.8 181.93q35.88 60.64 70.41 122.07c33.71 60 22.62 134.77-26.29 181.66-69.88 67-183.25 51.64-233.07-31.57-6.09-10.17-12-20.44-19.21-32.72C640 828 580.68 867.71 499.25 867.8S358.14 828.69 315.2 757.93c-6 10.43-10.89 18.92-15.8 27.4q-43.38 75-129.93 79.1c-71.17 3.24-139.6-54-150.17-124.65-5.41-36.16.35-69.85 18.65-101.26 55.43-95.16 110.76-190.38 166.81-285.17 24.6-41.59 58.93-71.92 107.1-83.93 56.53-14.1 113.63 10.41 137.52 58.94 23.28 47.28 12.6 98.1-28.67 133.67-20.43 17.61-42 33.89-62.25 51.72-22.87 20.16-40.36 44.56-50.7 73.46-11.86 33.14-10.2 65 14.87 91.66 25.48 27.11 57.64 28 91 18.29 28.07-8.15 48-28.35 68.6-47.6 15.46-14.47 30.54-29.6 47.44-42.22 26.67-19.92 57.77-27.8 90.68-23.27 40.83 5.61 70.5 36.2 79.87 79.29.37 1.72 2.64 9.66 3.69 10.18s-.41-29.93-2.25-42.06z" transform="translate(-17.36 -127.23)"/>
                <path fill={!isOpenOrScrolled ? "#fff" : "#243d98"} d="M561.57 85.77a33.8 33.8 0 11-33-34.89 33.82 33.82 0 0133 34.89zM451.2 101.55a25.3 25.3 0 11-24.76 25.15 25.17 25.17 0 0124.76-25.15zM494.18 0c10.08 1.4 16.59 6.71 16.43 17.16s-6.42 16.54-16.77 16.43-16.52-6.42-16.45-16.76S484.05 1.18 494.18 0z"/>
              </svg>
              <span className="uppercase p-0 ml-2 mt-0.5 text-3xl md:text-4xl font-normal font-robotocondensed tracking-tighter" style={{
                color: `${!isOpenOrScrolled ? "#fff" : "#1f4ea3"}`,
              }}>Oligomaster</span>
            </Link>
          </div>

          {/* Site navigation */}
          <nav className="hidden md:flex flex-grow">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <a href="https://oligomaster.com/" target="_blank" rel="noreferrer" className={`font-medium ${!isOpenOrScrolled ? 'text-white hover:text-slate-100' : 'text-gray-700 hover:text-gray-800'} px-5 py-3 flex items-center transition duration-150 ease-in-out`}>
                  Learn More
                </a>
              </li>
              <li>
                <a href="#contact" className={`btn-sm ${!isOpenOrScrolled ? 'text-black bg-white hover:bg-slate-100' : 'text-white bg-blue-600 hover:bg-blue-700'} ml-3`}>
                  <span>Contact Us</span>
                  {/* <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg> */}
                </a>
              </li>
            </ul>
          </nav>

          <div className="flex mb-0 md:hidden">
            <Hamburger toggled={isOpen} toggle={setOpen} size={28} label={`${isOpen ? 'Hide' : 'Show'} menu`} color={!isOpenOrScrolled ? "#fff" : "#1f4ea3"} hideOutline={false} />
            {isOpen && (
              <EscapeOutside onEscapeOutside={(e) => {
                if (!e || !e.target || ( e.target.className !== 'hamburger-react' && e.target.parentNode.className !== 'hamburger-react' )) {
                  setOpen(false);
                }
              }}>
                <nav className="absolute top-[64px] left-0 flex flex-col items-end bg-white shadow w-full mobile-menu lg:hidden overflow-y-scroll" style={{maxHeight: 'calc(100vh - 64px)'}}>
                  <a href="#contact" className="block text-black no-underline p-4 m-0 text-right border-b border-slate-200 w-full hover:bg-slate-50">
                    <span className="block container pr-4 my-0 mx-auto">Contact Us</span>
                  </a>
                  <a href="https://oligomaster.com/" className="block text-black no-underline p-4 m-0 text-right border-b border-slate-200 w-full hover:bg-slate-50">
                    <span className="block container pr-4 my-0 mx-auto">Learn More</span>
                  </a>
                </nav>
              </EscapeOutside>
            )}
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header
