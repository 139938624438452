import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children }) => {

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
        <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
